import { render, staticRenderFns } from "./ListStarters.vue?vue&type=template&id=3f46907f&scoped=true&"
import script from "./ListStarters.vue?vue&type=script&lang=ts&"
export * from "./ListStarters.vue?vue&type=script&lang=ts&"
import style0 from "./ListStarters.vue?vue&type=style&index=0&id=3f46907f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f46907f",
  null
  
)

export default component.exports